//Dependencies
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
//import Storage from "../../services/localStorage.service";
import Layout from "../../components/layouts";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as thankyouStyles from "./thankyou.module.scss"
import * as styles from "./os.module.scss";
import useScript from "../../hooks/useScript";

// Assets


const Returns = ({ location, pageContext }) => {

    const { t } = useTranslation();

    const easycomLocale = {
        se: "sv",
        dk: "da",
        eu: "en",
        no: "no",
        nl: "nl"
    }

    const easycom = useScript('https://star.easycom.com/easycom-api.js')

    useEffect(() => {
        if (typeof window !== undefined && easycom === 'ready') {
            const m = location.href.match(/#(.)+$/);
            let s = 'OjpmbG93bGlmZQ==';
            if (m && m.length > 0) {
                s = m[0].substr(1); // Remove leading "#"
            }
            window.Easycom.init({ drToken: s, languageCode: easycomLocale[pageContext.country] }) // Initiate token
        }
    }, [easycom])

    return (
        <Layout country={pageContext.country} location={location}>
            <div className={thankyouStyles.cdTrackingPage}>
                <div className="cd-max-width">
                    <div className={thankyouStyles.cdThankyouContainer}>
                        <div className={thankyouStyles.cdTrackingContainer}>
                            {/* <h3>{t('returns.register')}</h3> */}
                            {pageContext.country === 'se' ?
                                <>
                                    <h2>Retur och reklamation</h2>
                                    <p>Ibland går det inte alltid som planerat, men vi finns här för att hjälpa dig! Om du är missnöjd med din produkt eller av någon annan anledning vill returnera eller reklamera kan du göra det här.
                                        <p>När du har fyllt i formuläret så kommer du att få en QR-kod som fungerar som en returfraktsedel skickat till dig per sms. Du kan även gå tillbaka till denna sida för att spåra/hämta din fraktsedel igen om det skulle behövas.</p>
                                        <p><strong><em>Om du har andra leveransuppgifter än när ordern gjordes vänligen ange de nya uppgifterna i kommentarerna i formuläret.</em></strong></p>
                                        Hanteringstiden för en retur eller reklamation är ca 4-14 arbetsdagar.
                                        Har du gjort köpet från någon av våra återförsäljare behöver du kontakta dem för hjälp med din retur och reklamation.</p>
                                    <p>Och är det något vi kan hjälpa med finns vi tillgängliga på <a href="mailto:hello@flowlife.com">hello@flowlife.com</a></p>
                                </>
                                :
                                <>
                                    <h2>Return and Warranty</h2>
                                    <p>Sometimes things don’t always go as planned, but we’re here to help! If you are dissatisfied with your product or have any other reason to return or file a warranty claim, you can do so here.
                                        <p>Once you have filled out the form, you will receive a return label. You can also revisit this page to track/retrieve your shipping label if needed.</p>
                                        <p><strong><em>If you have changed the delivery information since the order was placed, please provide the new details in the comments section of the form.</em></strong></p>
                                        The processing time for a return or warranty claim is approximately 4-14 business days.
                                        If you made the purchase from one of our retailers, please contact them for assistance with your return and warranty.</p>
                                    <p>If there’s anything else we can assist you with, we’re available at <a href="mailto:hello@flowlife.com">hello@flowlife.com</a></p>
                                </>
                            }

                            <div className={`${thankyouStyles.cdTrackingBox}`}>
                                <div className={`${thankyouStyles.cdTrackingBoxInputs} ${styles.cdOsForm}`} >
                                    <easycom-returns style={{ height: 'auto', minHeight: '600px', width: '100%', border: 'none  ' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Returns